import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './services.css';
// Importa los íconos y las imágenes necesarias
import { FaStar, FaClock, FaEnvelope, FaAppleAlt } from 'react-icons/fa';

function National() {
  const cards = [
    { id: 1, title: "Gold", icon: <FaStar />, image: "/ruta/a/imagen1.jpg" },
    { id: 2, title: "12+12", icon: <FaClock />, image: "/ruta/a/imagen2.jpg" },
    { id: 3, title: "Mensajería", icon: <FaEnvelope />, image: "/ruta/a/imagen3.jpg" },
    { id: 4, title: "Perecederos", icon: <FaAppleAlt />, image: "/ruta/a/imagen4.jpg" },
    { id: 5, title: "12+12", icon: <FaClock />, image: "/ruta/a/imagen2.jpg" },
    { id: 6, title: "Mensajería", icon: <FaEnvelope />, image: "/ruta/a/imagen3.jpg" },
    { id: 7, title: "Perecederos", icon: <FaAppleAlt />, image: "/ruta/a/imagen4.jpg" },
    { id: 8, title: "12+12", icon: <FaClock />, image: "/ruta/a/imagen2.jpg" },
    // Agrega más tarjetas según sea necesario
  ];

  return (
    <div>
      <h3>Servicios Nacionales</h3>
      <Swiper
        spaceBetween={20}
        slidesPerView="auto"
        loop={false} // Desactivar el bucle para evitar que vuelva al inicio
        resistanceRatio={0} // Evitar el efecto de resorte
        
      >
        {cards.map((card) => (
          <SwiperSlide key={card.id} style={{ width: "400px" }}>
            <div className="card">
              <img src={card.image} alt={card.title} className="card-image" />
              <div className="card-content">
                <div className="card-icon">{card.icon}</div>
                <h4 className="card-title">{card.title}</h4>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default National;