import React from 'react';

function International() {
  return (
    <div>
      <h3>Servicios Internacionales</h3>
      <p>Descripción y detalles de los servicios internacionales...</p>
      {/* Agrega aquí el contenido detallado de los servicios internacionales */}
    </div>
  );
}

export default International;